.navbar {
    background-color: linear-gradient(90deg, #BD24DF 0%, #2D6ADE 97.15%);
    color: #fff;
    padding: 10px 0;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .navbar-logo {
    font-size: 1.5rem;
  }
  
  .navbar-menu {
    display: none;
  }
  
  .navbar-menu.active {
    display: block;
  }
  
  .navbar-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
  }
  
  .navbar-item {
    padding: 0 15px;
    cursor: pointer;
  }
  
  .navbar-toggle {
    cursor: pointer;
  }
  
  .bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    background-color: #fff;
    margin: 6px 0;
    transition: 0.4s;
  }
  
  .open .bar1 {
    transform: rotate(-45deg) translate(-9px, 6px);
  }
  
  .open .bar2 {
    opacity: 0;
  }
  
  .open .bar3 {
    transform: rotate(45deg) translate(-8px, -8px);
  }
  